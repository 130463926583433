// require('oneui/js/core/jquery.appear.min');
// require('oneui/js/core/jquery.countTo.min');
// require('oneui/js/core/jquery.placeholder.min');
// require('oneui/js/core/jquery.scrollLock.min');
// require('oneui/js/core/jquery.slimscroll.min');
// require('oneui/js/core/simplebar.min');
//
// require('oneui/js/plugins/bootstrap-datepicker/bootstrap-datepicker.min.js');
// require('oneui/js/plugins/bootstrap-colorpicker/bootstrap-colorpicker.min.js');
// require('oneui/js/plugins/bootstrap-maxlength/bootstrap-maxlength.min');
// require('oneui/js/plugins/datatables/jquery.dataTables.min');
// require('oneui/js/pages/base_tables_datatables');
//
// require('oneui/js/plugins/select2/select2.min');

import "./oneui/js/core/jquery-scrollLock.min";
import "./oneui/js/core/simplebar.min";
import "bootstrap";
import "./registrations";
import 'ckeditor'
// var app = require('oneui/js/app');

var Main = (function ($,window,document) {
    var that;

    var Main = function () {
        //public properties shared across instances
        that = this;
        that.name = 'Main';
    };

    // public static
    Main.staticMethod = function () {
    };

    Main.prototype = {
        init:function(){
            // app.initHelper('datepicker');
        },
        addCloneForm:function($collectionHolder)
        {
            // get the new index
            var index = $collectionHolder.data('index');

            // Get the data-prototype explained earlier
            // Replace '__name__' in the prototype's HTML to
            // instead be a number based on how many items we have
            var $prototype = $($collectionHolder.data('prototype').replace(/__name__/g, index));

            // increase the index with one for the next item
            $collectionHolder.data('index', index + 1);

            // add a delete link to the new form
            that.addCloneFormDeleteLink($prototype);

            // Display the form in the page
            $collectionHolder.append($prototype);

            return index;
        },
        addCloneFormDeleteLink:function($newForm)
        {
            var $deleteFormLink = $('<a href="#" class="btn btn-effect-ripple btn-xs btn-danger btn-remove"><i class="fa fa-times"></i></a>');
            $newForm.append($deleteFormLink);

            $deleteFormLink.on('click', function(e) {
                e.preventDefault();

                // remove the div for the invitationProduct form
                $newForm.remove();
            });
        }
    };
    return Main;
})($,window,document);

window.main = new Main();
$(document).ready(function () {
    window.main.init();
    // app.initHelpers(['datepicker', 'colorpicker', 'maxlength', 'select2']);
});